<template>
    <div>
        <v-autocomplete
            v-model="txt_year"
            :items="years"
            :rules="[rules.required]"
            required
            @change="onValueChanged()"
            dense></v-autocomplete>
    </div>
</template>

<script>
import DataMixin from "@/mixins/data.mixin";
export default {
    name: 'RoadtripYearDropdown',
    props: 
    {
        year: String
    },
    mixins: [DataMixin],
    beforeMount()
    {
        if (this.year)
        {
            this.txt_year = this.year;
        }
    },
    data() {
        return {
            txt_year: "",
            years: [
            "2023",
            "2022",
            "2021",
            "2020",
        ],
        }
    },
    computed: {
    },
    watch: {
        year: {
            deep: true,
            handler() {
                this.txt_year = this.year;
            }
        },
    },
    methods:
    {
        onValueChanged()
        {
            this.$emit('changed', this.txt_year);
        }
    }
}
</script>
<style>
.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 40px !important;
}
</style>