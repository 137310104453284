<template>
    <div>
        <LoadingIcon :isLoading="isLoading" />
        <div class="px-2">
            <v-container>
                <v-row no-gutters>
                    <v-col cols="7">
                        <p class="display-1 font-weight-thin mb-2">Voertuigen</p>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2" class="pl-2 year-container">
                        <RoadtripYearDropdown :year="selectedYear" @changed="onYearChanged($event)" />
                    </v-col>
                    <v-col cols="2" class="pl-2 quarter-container">
                        <RoadtripQuarterDropdown :quarter="selectedQuarter" @changed="onQuarterChanged($event)" />
                    </v-col>
                </v-row> 
                <StatisticInfoCards 
                    :title1="`Totaal:`"
                    :value1="totalVehicles"
                    :title2="`Top merk:`"
                    :value2="topMake"
                    :value2class="`title`"
                    :title3="`Nieuw:`"
                    :value3="newVehicles"
                    />
                <v-row>
                    <v-col>
                        <div class="pb-3">{{accountDescription}}</div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <p class="header-title mt-0 mb-n10">Top merken</p>
                    </v-col>
                    <template>
                        <div class="chart-wrapper">
                            <chart :option="chartOptionsBar"></chart>
                        </div>
                    </template>
                </v-row>
                <v-row no-gutters class="mb-5">
                    <v-col>
                        <p class="header-title mt-0 mb-0">Geregistreerd</p>
                    </v-col>
                    <v-col cols="12" class="ma-0 pt-0 px-0 sort-container">
                        <v-btn plain
                            class="mr-4 pa-0 black white--text text-capitalize text-start"
                            @click="currentSort = 'merknaam'">
                            Merknaam
                            <v-icon large right dark class="pr-2">{{currentSort == 'merknaam' ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
                        </v-btn>
                        <v-btn plain
                            class="mr-4 pa-0 black white--text text-capitalize text-start"
                            @click="currentSort = 'meegereden'">
                            Meegereden
                            <v-icon large right dark class="pr-2">{{currentSort == 'meegereden' ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
                        </v-btn>
                        <v-btn plain
                            class="mr-4 pa-0 black white--text text-capitalize text-start"
                            @click="currentSort = 'actief'">
                            Laatst actief
                            <v-icon large right dark class="pr-2">{{currentSort == 'actief' ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" class="pt-3 pb-1 px-0" v-for="(participant, index) in sortedArray" :key="index">
                        <v-card class="participant-card primary" @click="goToCar(participant.carId)">
                            <v-card-title
                            class="subtitle-1 cols-12 pt-2 pb-0">
                                {{ getCardText(participant) }}
                            </v-card-title>
                            <v-list class="pa-0 primary">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-thin">{{ getCardSubText(participant) }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
import LoadingIcon from '@/components/common/LoadingIcon';
import RouteMixin from "@/mixins/route.mixin";
import FormatMixin from "@/mixins/format.mixin";
import StatisticInfoCards from "@/components/admin/StatisticInfoCards";
import RoadtripYearDropdown from '@/components/common/options/RoadtripYearDropdown';
import RoadtripQuarterDropdown from '@/components/common/options/RoadtripQuarterDropdown';

import "@/plugins/echarts";
export default {
    name: 'Voertuigen',
    components: 
    {
        LoadingIcon,
        StatisticInfoCards,
        RoadtripYearDropdown,
        RoadtripQuarterDropdown
    },
    mixins: [RouteMixin, FormatMixin],
    data: () => ({
        selectedYear: ``,
        selectedQuarter: 1,
        isMounted: false,
        isLoading: false,
        totalVehicles: '',
        topMake: '',
        newVehicles: '',
        accountDescription: "Hierboven zijn de belangrijkste statistieken van de huidige periode weergegeven. Het is mogelijk om alle gegevens op deze pagina per kwartaal en per jaar te sorteren. Daarnaast zijn alle voertuigen hieronder op merknaam gesorteerd.",
        currentSort: 'merknaam',
        participants: [
            {
                name: "Ewoud Vlieg",
                daysLastActive: 3,
                car: "Porsche Boxster S 3.2",
                accountId: '0',
                carId: '123',
                rideCount: 1,
                kilometerRide: 678
            },
            {
                name: "Adriaan de Boet",
                daysLastActive: 7,
                car: "Ferrari",
                accountId: '0',
                carId: '123',
                rideCount: 3,
                kilometerRide: 1024
            },
            {
                name: "Jedalisa Sevilla",
                daysLastActive: 70,
                car: "Toyota Yaris",
                accountId: '0',
                carId: '123',
                rideCount: 2,
                kilometerRide: 533
            },
            {
                name: "Marijn van der Leer",
                daysLastActive: 40,
                car: "BMW",
                accountId: '0',
                carId: '123',
                rideCount: 2,
                kilometerRide: 612
            },
            {
                name: "Ruben Vlieg",
                daysLastActive: 1,
                car: "Honda Civic",
                accountId: '0',
                carId: '123',
                rideCount: 6,
                kilometerRide: 2049
            },
            {
                name: "Wijnand Zwart",
                daysLastActive: 14,
                car: "Tesla Z",
                rideCount: 14,
                accountId: '0',
                carId: '123',
                kilometerRide: 4781
            },
        ],

        chartOptionsBar: {
            xAxis: {
                data: ['BMW', 'Porsche', 'Volvo'],
                axisLabel: {
                    rotate: 90 
                },
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    show: true,
                }
            },
            series: [
                {
                type: 'bar',
                data: [
                    {
                        value: 50,
                        itemStyle: {color: '#127ac2', width: '3px'},
                    },
                    {
                        value: 58,
                        itemStyle: {color: '#e53232'},
                    },
                    {
                        value: 24,
                        itemStyle: {color: '#60afff'},
                    }],
                }
            ],
        }
    }),
    computed: {
        sortedArray() 
        {
            let sortedParticipants = this.participants;
		
            sortedParticipants = sortedParticipants.sort((a,b) => {
                let text1 = "",
                    text2 = "";

                switch(this.currentSort)
                {
                    case "merknaam":
                        text1 = a.name.toLowerCase();
                        text2 = b.name.toLowerCase();
                        break;
                    case "meegereden":
                        text1 = a.rideCount;
                        text2 = b.rideCount;
                        break;
                    case "actief":
                        text1 = a.daysLastActive;
                        text2 = b.daysLastActive;
                        break;
                }

                if (text1 < text2) {
                    return -1
                }
                if (text1 > text2) {
                    return 1
                }
                return 0
            })
            return sortedParticipants;
        },
    },
    mounted(){
        this.selectedYear = `${this.getCurrentYear()}`;
        this.selectedQuarter = this.getCurrentQuarter();
        this.isMounted = true;
    },
    methods: 
    {
        onYearChanged(year)
        {
            this.selectedYear = year;
            this.getVehicleStatisticsByTimeFrame();
        },
        onQuarterChanged(quarter)
        {
            this.selectedQuarter = quarter;
            this.getVehicleStatisticsByTimeFrame();
        },
        getVehicleStatisticsByTimeFrame()
        {
            if(this.isMounted == false || this.isMounted == undefined) return;

            this.isLoading = true;
            let request = {
                year: this.selectedYear,
                quarter: this.selectedQuarter
            }
           
            this.$store.dispatch('vehicleModule/getVehicleStatisticsByTimeFrame', request)
            .then(data => {
                this.totalVehicles = `${data.totalVehicles}`;
                this.topMake = `${data.topMake}`;
                this.newVehicles = `${data.newVehicles}`;
                this.isLoading = false;
            })
            .catch(() =>
            {
                this.isLoading = false;
            });
        },
        getCardText(item)
        {
           return `${item.car} | ${item.name}`;
        },
        getCardSubText(item)
        {
           return `Aantal roadtrips meegereden: ${item.rideCount} (${item.kilometerRide} km)`;
        }
    }
}
</script>
<style scoped>
.header
{
    display: inline-flex;
}

.edit-icon
{
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.v-input
{
    height: 25px;
}

.sort-container button.v-btn:not(.v-btn--round).v-size--default
{
    border-bottom: 1px solid #858282 !important;
    border-radius: 0px;
}

.theme--dark.v-card.participant-card,
.participant-card .theme--dark.v-list
{
    background-color: #005E39;
}

.participant-card .v-card__title
{
    margin-bottom: -10px;
}

.v-sheet.v-card.participant-card 
{
    border-radius: 10px;
}

.chart-wrapper {
  width: 100%;
  height: 300px;
}

.echarts {
  width: 100%;
  height: 100%;
}
</style>