<template>
    <v-row class="info-cards">
            <v-col cols="4" class="py-0">
               <v-card
                    class="mx-auto primary rounded-lg darken-2"
                    height="100">
                    <v-card-text class="pb-0 pt-2 px-2 subtitle-1 font-weight-light">
                        {{title1}}
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <div class="text-h4 pb-2 mt-n1">
                            <span class="font-weight-light" :class="value1class">
                                {{value1}}
                            </span>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="4" class="py-0">
                <v-card
                    class="mx-auto primary rounded-lg darken-2"
                    height="100">
                    <v-card-text class="pb-0 pt-2 px-2 subtitle-1 font-weight-light">
                        {{title2}}
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <div class="text-h4 pb-2 mt-n1">
                            <span class="font-weight-light" :class="value2class">
                                {{value2}}
                            </span>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="4" class="py-0">
                <v-item>
                    <v-card
                        class="mx-auto primary rounded-lg darken-2"
                        height="100">
                        <v-card-text class="pb-0 pt-2 px-2 subtitle-1 font-weight-light">
                            {{title3}}
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <div class="text-h4 pb-2 mt-n1">
                                <span class="font-weight-light" :class="value3class">
                                    {{value3}}
                                </span>
                            </div>
                        </v-card-actions>
                    </v-card>
                </v-item>
            </v-col>
        </v-row>
</template>


<script>
export default {
    name: 'StatisticInfoCards',
    components: 
    {
    },
    data () {
        return {
        }
    },
    props: 
    {
        title1: String,
        value1: String,
        value1class: String,
        title2: String,
        value2: String,
        value2class: String,
        title3: String,
        value3: String,
        value3class: String,
    },
    methods: {
    },
}
</script>
