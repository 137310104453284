<template>
    <div>
        <v-select 
            v-model="txt_quarter"
            :items="quarters"
            offset-y
            item-text="item2"
            item-value="item1"
            :rules="[rules.required]"
            required
            @change="onValueChanged()"
            dense></v-select>
    </div>
</template>

<script>
import DataMixin from "@/mixins/data.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
export default {
    name: 'RoadtripQuarterDropdown',
    props: 
    {
        quarter: Number
    },
    mixins: [DataMixin, MenuHelperMixin],
    beforeMount()
    {
        if (this.quarter)
        {
            this.txt_quarter = this.quarter;
        }
    },
    data() {
        return {
            txt_quarter: {},
            quarters: 
            [
                {
                    item1: 1,
                    item2: "Q1"
                },
                {
                    item1: 2,
                    item2: "Q2"
                },
                {
                    item1: 3,
                    item2: "Q3"
                },
                {
                    item1: 4,
                    item2: "Q4"
                },
            ]
        }
    },
    computed: {
    },
    watch: {
        quarter: {
            deep: true,
            handler() {
                this.txt_quarter = this.quarter;
            }
        },
    },
    methods:
    {
        onValueChanged()
        {
            this.$emit('changed', this.txt_quarter);
        }
    }
}
</script>