import Vue from 'vue';
import Echarts from 'vue-echarts';

import 'echarts/lib/chart/bar';

import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import { GridComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([BarChart, GridComponent, CanvasRenderer]);

Vue.component('chart', Echarts);